export const colors = {
  socar: "#00B8FF",
  black: "#000000",
  transparent: "transparent",

  white: "#ffffff",
  white_rgb: "rgb(255, 255, 255)",

  grey005: "#fefefe",
  grey010: "#fdfdfd",
  grey020: "#f8f9fb",
  grey025: "#f2f4f6",
  grey030: "#e9ebee",
  grey040: "#c5c8ce",
  grey045: "#a7aeb9",
  grey048: "#9099a4",
  grey050: "#646f7c",
  grey060: "#374553",
  grey070: "#28323c",
  grey070_rgb: "rgb(40, 50, 60)",
  grey080: "#161d24",

  blue010: "#f0faff",
  blue015: "#def4ff",
  blue020: "#b2e5ff",
  blue030: "#7dd5ff",
  blue040: "#44c4ff",
  blue050: "#00b0ff",
  blue060: "#00aafe",
  blue070: "#009bef",
  blue080: "#0088da",

  coral000: "#fff7f8",
  coral010: "#ffeef1",
  coral020: "#ffd5d9",
  coral030: "#f8a8a5",
  coral040: "#f28882",
  coral050: "#ff6f61",
  coral060: "#ff6247",
  coral070: "#f75b48",
  coral080: "#e45141",

  navy010: "#edf3ff",
  navy020: "#cae2ff",
  navy030: "#7bb0ff",
  navy040: "#5685fd",
  navy050: "#0069ff",
  navy060: "#1b56ec",
  navy070: "#1749C8",
  navy080: "#0B38AA",

  darknavy010: "#e0ebf9",
  darknavy020: "#BADEF9",
  darknavy030: "#63B4F2",
  darknavy040: "#1075CE",
  darknavy050: "#00469D",
  darknavy060: "#003C87",
  darknavy070: "#003270",
  darknavy080: "#002554",

  red010: "#FFEDF1",
  red020: "#F9D0D6",
  red030: "#f697a4",
  red040: "#f26175",
  red050: "#ff2c51",
  red060: "#EF163C",
  red070: "#DA183A",
  red080: "#C90326",

  yellow010: "#FFFCEA",
  yellow020: "#FFF8CC",
  yellow030: "#FFF3A9",
  yellow040: "#FFEE86",
  yellow050: "#fff028",
  yellow060: "#ffd324",
  yellow070: "#FFC400",
  yellow080: "#F6B100",

  green010: "#E5F5ED",
  green020: "#C1F2D9",
  green030: "#97F7C3",
  green040: "#51F5B0",
  green050: "#1CEC94",
  green060: "#0FDF88",
  green070: "#10CC86",
  green080: "#00B16F"
};
