import { Button } from "@socar-inc/frontend-design-system";
import { Stack } from "./Stack";

interface ErrorWithRefreshProps {
  onClickRefresh: () => void;
  title?: string;
  description?: string;
  refreshText?: string;
}

/**
 * @description 에러가 발생했을 때 보여줄 에러 컴포넌트입니다. 새로고침 버튼이 있습니다.
 * @param {() => void} onClickRefresh - 새로고침 버튼을 눌렀을 때 실행할 함수
 */
export const ErrorWithRefresh = ({
  onClickRefresh,
  title,
  description,
  refreshText = "새로고침"
}: ErrorWithRefreshProps) => {
  return (
    <Stack
      className="tw-min-h-full tw-w-full tw-text-center tw-text-grey060 tw-bg-white"
      spacing={36}
      p={24}
      style={{ paddingTop: "30vh" }}
    >
      <div>
        <Stack spacing={8} className="tw-grow">
          <div className="subtitle2">{title}</div>
          <div className="body2">{description}</div>
        </Stack>
        <Button
          onClick={onClickRefresh}
          type="regularLine"
          color="grey"
          text={refreshText}
          className="tw-mt-36"
        />
      </div>
    </Stack>
  );
};
