import { datadogRum } from "@datadog/browser-rum";
import lokalise from "@src/libs/locales/ko/ko.json";
import { AxiosError } from "axios";
import { ErrorWithRefresh } from "./ErrorWithRefresh";

/**
 * @description 에러가 발생했을 때 보여줄 기본 에러 컴포넌트입니다. 네트워크 에러와 클라이언트 에러를 구분하여 보여줍니다.
 * @param {AxiosError | Error} error - 에러 객체
 * @param {() => void} resetErrorBoundary - react query를 다시 실행하는 함수
 */
export const DefaultErrorFallback = ({
  error
}: {
  error: AxiosError | Error;
  resetErrorBoundary: () => void;
}) => {
  const reloadPage = () => {
    window.location.reload();
  };

  const isNetworkError = error instanceof AxiosError;

  if (isNetworkError) {
    datadogRum.addError(error, {
      type: "network",
      statusCode: error?.response?.status,
      resource: error?.config?.url,
      method: error?.config?.method,
      data: error?.config?.data,
      response: error?.response?.data,
      pathname: window.location.pathname,
      href: window.location.href
    });
  } else {
    datadogRum.addError(error, {
      type: "client",
      path: window.location.pathname,
      href: window.location.href
    });
  }

  // 네트워크 에러
  if (isNetworkError) {
    return (
      <ErrorWithRefresh
        onClickRefresh={reloadPage}
        title={lokalise.error_notice_network}
        description={lokalise.error_guide_contact_after_refresh}
      />
    );
  }

  // 클라이언트 에러
  return (
    <ErrorWithRefresh
      onClickRefresh={reloadPage}
      title={lokalise.error_notice_client}
      description={lokalise.error_guide_contact_after_refresh}
    />
  );
};
