import { flexVariant } from "@src/constants/variants/flexVariant";
import type { CommonSystemProps, Spacing } from "@src/types/styleType";
import type { CSSProperties, ReactNode } from "react";

interface StackProps extends CommonSystemProps {
  direction?: "col" | "row" | "col-reverse" | "row-reverse";
  spacing?: Spacing;
  style?: CSSProperties;
  className?: string;
  children: ReactNode;
  flexType?: "flex" | "inline-flex";
  items?:
    | "center"
    | "start"
    | "end"
    | "stretch"
    | "baseline"
    | "initial"
    | "inherit";
  justify?:
    | "center"
    | "start"
    | "end"
    | "between"
    | "around"
    | "evenly"
    | "initial"
    | "inherit";
}

/**
 * @description Stack 컴포넌트는 정렬할 요소를 감싸는 컨테이너 역할을 합니다.
 * @param {string} direction - 정렬 방향을 결정합니다.
 * @param {number} spacing - 요소 사이의 간격을 결정합니다.
 * @param {CSSProperties} style - Stack 컴포넌트의 스타일을 결정합니다.
 * @param {string} className - Stack 컴포넌트의 클래스를 결정합니다.
 * @param {ReactNode} children - Stack 컴포넌트의 자식 요소를 결정합니다.
 * @param {string} flexType - Stack 컴포넌트의 flex 타입을 결정합니다. (기본값: flex)
 * @example
 * <Stack direction="row" spacing={8} mt={16}>
 *  <div>요소 1</div>
 *  <div>요소 2</div>
 * </Stack>
 */
export const Stack = ({
  direction = "col",
  spacing = 0,
  style,
  className = "",
  children,
  flexType = "flex",
  items = "initial",
  justify = "initial",
  ...systemProps
}: StackProps) => {
  return (
    <div
      style={style}
      className={flexVariant({
        ...systemProps,
        direction,
        justify,
        items,
        flexType,
        "space-x": direction === "row" ? spacing : undefined,
        "space-y": direction === "col" ? spacing : undefined,
        "space-x-reverse": direction === "row-reverse" ? spacing : undefined,
        "space-y-reverse": direction === "col-reverse" ? spacing : undefined,
        class: className
      })}
    >
      {children}
    </div>
  );
};
