export const borderRadius = {
  0: "0px",
  1: "1px",
  2: "2px",
  3: "3px",
  4: "4px",
  6: "6px",
  8: "8px",
  10: "10px",
  12: "12px",
  16: "16px",
  half: "50%",
  full: "100%"
};
