import { useQueryErrorResetBoundary } from "@tanstack/react-query";
import { type PropsWithChildren } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { DefaultErrorFallback } from "./DefaultErrorFallback";

export const AsyncBoundary = ({ children }: PropsWithChildren) => {
  const { reset } = useQueryErrorResetBoundary();

  return (
    <ErrorBoundary onReset={reset} fallbackRender={DefaultErrorFallback}>
      {children}
    </ErrorBoundary>
  );
};
