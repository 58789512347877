import { generateTwVariants } from "@src/utils/variants/generateVariants";
import { tv } from "tailwind-variants";

export const spacingVariant = tv({
  variants: {
    m: generateTwVariants("spacing", "m"),
    mt: generateTwVariants("spacing", "mt"),
    mb: generateTwVariants("spacing", "mb"),
    ml: generateTwVariants("spacing", "ml"),
    mr: generateTwVariants("spacing", "mr"),
    mx: generateTwVariants("spacing", "mx"),
    my: generateTwVariants("spacing", "my"),
    p: generateTwVariants("spacing", "p"),
    pt: generateTwVariants("spacing", "pt"),
    pb: generateTwVariants("spacing", "pb"),
    pl: generateTwVariants("spacing", "pl"),
    pr: generateTwVariants("spacing", "pr"),
    px: generateTwVariants("spacing", "px"),
    py: generateTwVariants("spacing", "py")
  }
});
